@import '~app/mixins';

.dropdownMenu {
  position: relative;

  .control {
    height: 26px;
    width: 130px;
    border: 1px solid $color-grey;
    border-radius: 4px;
    background: $color-white;
    padding: 6px 35px 6px 12px;
    transition: all $transition-delay;
    color: $color-grey;
    font-size: 14px;
    cursor: pointer;
    display: flex;
    align-items: center;

    &.small {
      font-size: 12px;
      height: 26px;

      .iconCaretDown {
        top: 9px;
      }
    }

    &:focus {
      background: $color-white;

      @include primary-color-box-shadow-inset();
    }

    .value {
      width: 200px;

      @include text-overflow();
    }

    .iconCaretDown {
      width: 12px;
      height: 9px;
      position: absolute;
      right: 9px;
    }
  }

  .icon {
    width: 22px;
    height: 22px;

    use {
      fill: $color-grey-light;
    }
  }

  .dropdown {
    $sizeArrow: 5px;

    position: absolute;
    right: 0;
    border: 2px solid transparent;
    background: $color-white;
    box-shadow: $dropdown-shadow;
    border-radius: 2px;
    padding: 8px;
    display: none;
    z-index: 2;
    width: 160px;
    color: $color-grey;

    @include primary-border-color();

    .caption {
      margin-bottom: 6px;
      pointer-events: none;
      padding: 4px 0;
      text-align: center;
      border-bottom: 1px solid $color-grey-lighter;
      font-weight: bold;
    }

    .item {
      padding: 5px 8px;

      &:hover,
      &.selected {
        background-color: $color-light-blue;
      }

      &:not(.selected) {
        cursor: pointer;
      }
    }

    &:before,
    &:after {
      content: '';
      position: absolute;
    }

    &:before {
      right: 40px;
      width: $sizeArrow;
      height: $sizeArrow;
      transform: rotate(45deg);
      background: $color-white;

      @include primary-color-box-shadow();
    }

    &:after {
      height: $sizeArrow+2;
      width: 15px;
      right: 35px;
      background: $color-white;
    }

    &.top {
      margin-bottom: 5px;
      bottom: 100%;

      &:before {
        bottom: -3px;
      }

      &:after {
        bottom: 0;
      }
    }

    &:not(.top) {
      margin-top: 5px;
      top: 100%;

      &:before {
        top: -3px;
      }

      &:after {
        top: 0;
      }
    }
  }

  &.left {
    .dropdown {
      right: unset;
      left: 0;

      &:before {
        right: unset;
        left: 40px;
      }

      &:after {
        right: unset;
        left: 35px;
      }
    }
  }

  &.iconMode {
    .dropdown {
      &:before {
        right: 12px;
      }

      &:after {
        right: 9px;
      }
    }
  }

  &.open {
    .dropdown {
      display: block;
    }

    .icon use {
      fill: #fff !important;
    }
  }
}
